import React from 'react';

export default function Footer() {
  return (
    <header id="header">
        <div className="logo"><img src="/images/logo.png" alt="BNL2"/></div>
        <h1>&nbsp;Plan, Build, <br/>Destroy!</h1>
    </header>
  );
}
