import React from 'react';
import config from '../../config';

const Footer = ()  => {
    return (
        <>
            <footer id="footer">
                <ul className="icons">
                    {config.logoLinks.map(social => {
                        const {icon, id, url} = social;
                        return (
                            <li className="logo" key={id}>
                                <a href={url} target="_blank" rel="noreferrer">
                                    <img src={icon} alt=""/>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <ul className="icons">
                    {config.socialLinks.map(social => {
                        const {icon, id, url} = social;
                        return (
                            <li className="social" key={id}>
                                <a href={url} target="_blank" rel="noreferrer">
                                    <img src={icon} alt=""/>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </footer>
        </>);
};

export default Footer;
