module.exports = {
  siteTitle: 'Block N Load 2 | Coming Soon', // <title>
  manifestName: 'bnl2',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: './src/assets/img/logo.png',
  logoLinks: [
    {
      icon: '/images/logo--toadman.png',
      url: 'https://toadmaninteractive.com/',
      id: 1
    },
    {
      icon: '/images/logo--eg7.png',
      url: 'https://www.enadglobal7.com/',
      id: 2
    }
  ],
  // social
  socialLinks: [
    {
      icon: '/images/ico--fb.svg',
      url: 'https://www.facebook.com/blocknload2/',
      id: 1
    },
    {
      icon: '/images/ico--discord.svg',
      url: 'https://discord.gg/bnlofficial',
      id: 2
    },
    {
      icon: '/images/ico--twitter.svg',
      url: 'https://twitter.com/block_n_load2',
      id: 3
    },
    {
      icon: '/images/ico--vk.svg',
      url: 'https://vk.com/blocknloadii',
      id: 4
    },
    {
      icon: '/images/ico--steam.svg',
      url: 'https://store.steampowered.com/app/1551810/Block_N_Load_2/',
      id: 5
    },
  ],
};
