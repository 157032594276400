import React, {Component} from 'react';

export class EmailForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEmail: false,
            isAgree: false,
            isPolicy: false,
            message: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        let email = {
            email: this.inputEmail.value
        };

        // отправка Email
        let response = await fetch('/api/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(email)
        });

        // todo: добавить проверку и убрать
        let result = await response.json();
        console.log(result.message);

        this.setState({message: 'Subscription was successful! stay tuned for news!'});

    }

    onChangeAgree = (e) => {

        this.setState(initialState => ({
            isAgree: !initialState.isAgree,
        }));

    }

    onChangePolicy = (e) => {

        this.setState(initialState => ({
            isPolicy: !initialState.isPolicy,
        }));

    }

    onChangeEmail = (e) => {
        let email = e;

        if (typeof email !== "undefined") {
            let lastAtPos = email.target.value.lastIndexOf('@');
            let lastDotPos = email.target.value.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.target.value.indexOf('@@') === -1 && lastDotPos > 2 && (email.target.value.length - lastDotPos) > 2)) {
                this.setState((state) => {
                    return {isEmail: false}
                });
            } else {
                this.setState((state) => {
                    return {isEmail: true}
                });
            }
        }

    }


    Validate() {
        let isAgree = this.state.isAgree;
        let isPolicy = this.state.isPolicy;
        let isEmail = this.state.isEmail;
        let button = document.getElementById('sendButton');

        if (isAgree && isPolicy && isEmail) {
            button.removeAttribute('disabled');
        } else {
            button.setAttribute('disabled', 'disabled');
        }
    }

    componentDidUpdate() {
        this.Validate();
    }

    render() {
        const {message} = this.state;
        return (
            <form id="signup-form" onSubmit={this.onSubmit} method="post" action="https://crius.toadman.io/bnl2-web/api/subscribe">
                <div className={`${message ? 'hidden' : ''} fields`}>
                    <div className="signup--first">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            ref={email => (this.inputEmail = email)}
                            onChange={this.onChangeEmail}
                        />
                        <input type="submit" id="sendButton" disabled value="Subscribe"/>
                    </div>
                    <div className="signup--second">
                        <label className="checkbox-other">
                            <input name="agree"
                                   type="checkbox"
                                   checked={this.state.isAgree}
                                   onChange={this.onChangeAgree}
                                   value="agree"
                            />
                            <span>I agree to receive news <br/>and promotional content via email</span>
                        </label>
                        <label className="checkbox-other">
                            <input
                                name="policy"
                                type="checkbox"
                                checked={this.state.isPolicy}
                                onChange={this.onChangePolicy}
                                value="agree"
                            />
                            <span>I’ve read and&nbsp;understood the <a target="_blank" rel="noreferrer" href="/policy.pdf">Privacy Policy</a></span>
                        </label>
                    </div>
                </div>
                <div className={`${message ? 'visible' : ''} message`}>
                    <p>{this.state.message}</p>
                    <p>
                        <a href="/" className="button">
                            Cool!
                        </a>
                    </p>
                </div>
            </form>
        );
    }
}

export default EmailForm;
